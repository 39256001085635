import {ArrowLeftIcon, CheckCircleIcon, ChevronDownIcon} from "@heroicons/react/24/outline";
import {DropdownMenuTrigger} from "@radix-ui/react-dropdown-menu";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import useGetMe from "../hooks/useGetMe";
import useSocket from "../hooks/useSocket";
import {Button} from "../ui/Button";
import {Link, useLocation} from "wouter";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem} from "../ui/Dropdown";
import {DEFAULT_TOAST_CONFIG} from "../utils/toast";
import USER_SETUP_STEPS from "./steps";
import useUserProfileCompletionCalculation from "./useUserProfileCompletionCalculation";
import {calculateCompletion} from "./utils";

export default function UserSetup() {
  const [socket] = useSocket();
  const {user, setup: {matchedProfile}} = useSelector(state => state.user);
  const containerRef = useRef(null);
  const [propsBag, setPropsBag] = useState({});
  const [step, setStep] = useState();
  const [loaded, {firstIncompleteStep, canGoBackTo, stepCompletionStatus, percentage}] = useUserProfileCompletionCalculation(step);
  const [, navigate] = useLocation();
  const getMe = useGetMe();

  useEffect(() => {
    if (!loaded) return;

    setStep(firstIncompleteStep);
  }, [loaded, firstIncompleteStep]);

  const nextStep = useCallback((props = {}) => {
    return (forcedStep) => {
      if (!forcedStep && step === USER_SETUP_STEPS.length - 1) {
        toast.success('User setup complete!', DEFAULT_TOAST_CONFIG);
        navigate('/');
      }

      getMe().then((me) => {
        const {percentage, stepCompletionStatus: statuses} = calculateCompletion(me, matchedProfile);
        const nextStepIdx = statuses.findIndex(([isComplete, stepIdx]) => !isComplete && stepIdx > step);
        console.log(statuses, nextStepIdx, step, percentage);

        if (!forcedStep && nextStepIdx < 0 && percentage === 100) {
          debugger;
          return;
        }

        setPropsBag({...propsBag, ...props});
        setStep(forcedStep || nextStepIdx);
      });
    }
  }, [matchedProfile, getMe, step]);

  function reset(status = {}) {
    socket.emit('profiles:setup:reset', status, () => {
      window.location.reload();
    });
  }

  function goBack() {
    if (!step) {
      navigate('/');
      return;
    }

    if (!canGoBack) return;

    setStep(canGoBackTo);
  }

  function scrollTop() {
    containerRef.current.scrollTo({top: 0, behavior: 'smooth'});
  }

  function saveAndExit() {
    getMe();
    navigate('/');
  }

  const canGoBack = useMemo(() => step > 0 && canGoBackTo > -1 && canGoBackTo < step, [canGoBackTo, step]);
  const Component = useMemo(() => user && USER_SETUP_STEPS[step]?.component, [step, user, USER_SETUP_STEPS]);
  const canSkip = useMemo(() => user && USER_SETUP_STEPS[step]?.canSkip && USER_SETUP_STEPS[step]?.canSkip(user), [user, step]);
  const nextIncompleteStep = useMemo(() => stepCompletionStatus.findIndex(([isComplete, stepIdx]) => !isComplete && stepIdx > step), [step, stepCompletionStatus]);
  const activePercentage = useMemo(() => 100 / USER_SETUP_STEPS.length * (step), [step]);
  const willSaveAndExit = useMemo(() => !canSkip || step === USER_SETUP_STEPS - 1, [step, canSkip]);

  return (
    <div ref={containerRef} className='fixed bg-gray-800 z-50 top-0 left-0 h-full w-full flex flex-col overflow-y-auto'>
      {
        loaded && Component ? (
          <>
            {
              percentage < 100 ? (
                <>
                  <div className='sticky top-0 w-full bg-gray-800 font-semibold p-5 px-5 z-40' onClick={scrollTop}>Strengthen your account</div>
                  <div className='grow p-5 pt-0'>
                    <Component scrollTop={scrollTop} next={nextStep} {...propsBag} />
                  </div>
                  <div className='sticky bottom-0 w-full'>
                    <div className='h-1.5 w-full bg-gray-900'>
                      <div className={`bg-white ${activePercentage === 100 ? 'rounded-none' : 'rounded-full rounded-l-none'} h-full transition-all`} style={{width: `${activePercentage}%`}}></div>
                    </div>
                    <div className='flex items-center justify-between bg-gray-600 p-5'>
                      {canGoBack ? <Button onClick={goBack} variant='ghost'>Back</Button> : <span></span>}
                      <Button disabled={!willSaveAndExit && !canSkip} onClick={() => {
                        if (willSaveAndExit) return saveAndExit();
                        if (canSkip) return nextStep()(nextIncompleteStep);
                      }} variant='ghost'>{willSaveAndExit ? 'Save & exit' : 'Skip'}</Button>
                    </div>
                  </div>
                </>
              ) : (
                <div className='absolute z-50 bg-gray-800 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                  <div className='flex flex-col items-center justify-center space-y-5'>
                    <div><CheckCircleIcon className='h-20' /></div>
                    <span className='font-semibold text-xl'>You're all done!</span>
                    <Link to='/' className='flex items-center'>
                      <ArrowLeftIcon className='h-5 mr-2' />
                      <span>Back to fuse</span>
                    </Link>
                  </div>
                </div>
              )
            }
          </>
        ) : (
          <div className='absolute z-50 bg-gray-800 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>Loading...</div>
        )
      }

      {
        user.role_type === 'admin' ? (
          <div className='fixed top-5 right-5 z-50 flex flex-col space-y-5'>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <ChevronDownIcon className='h-6' />
              </DropdownMenuTrigger>
              <DropdownMenuContent align='start' className='mr-5'>
                <DropdownMenuItem onClick={() => reset({})}>
                  Reset ALL
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => reset({step: 'PHONE_VER'})}>
                  Reset - Phone Verification
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        ) : null
      }
    </div>
  )
}
