import {useState} from "react";
import {Button} from "../ui/Button";
import PhoneNumberInput from "../ui/PhoneNumberInput";
import {savePhoneNumber as updatePhoneNumber} from './utils';

export default function NewPhoneNumber({next}) {
  const [saving, setSaving] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [errorMessage, setErrorMessage] = useState();

  function setAndVerifyNumber(value) {
    setSaving(true);

    updatePhoneNumber(value)
    .then(({success, msg}) => {
      if (success) {
        next({})();
        return;
      }

      setErrorMessage(msg);
    })
    .finally(() => {
      setSaving(false);
    });
  }

  return (
    <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -mt-20 flex justify-center items-center w-full'>
      <div className='max-w-lg w-full space-y-5'>
        <span>Your phone number</span>
        <PhoneNumberInput defaultValue='' onChange={(valid, value) => valid && setPhoneNumber(value)} />
        {errorMessage ? <div className='text-red-500'>{errorMessage}</div> : null}
        <div className='flex items-center justify-end'>
          <Button disabled={saving} onClick={() => setAndVerifyNumber(phoneNumber)}>Save</Button>
        </div>
      </div>
    </div>
  )
}
