import {useReactTable} from "@tanstack/react-table";
import {useContext, useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {Link, useLocation, useParams} from "wouter";
import FilterContext from "../Contexts/FilterContext";
import {getDefaultReactTableConfig} from "../Data";
import {useDpeGrowth} from "../hooks/useFetchDataApi";
import usePrint from "../hooks/usePrint";
import useReducedColumns from "../hooks/useReducedColumns";
import {Button} from "../ui/Button";
import DataTable from "../ui/DataTable";
import {downloadCsv, millionsAndBillionsFormatter} from "../utils";
import {selectColorOnGradient} from "../utils/colors";
import ChartArea from "./ChartArea";
import ChartBar from "./ChartBar";
import {
  ArrowDownTrayIcon, ArrowLeftIcon,
  MagnifyingGlassPlusIcon,
} from "@heroicons/react/24/outline";
import {Card, CardContent, CardHeader, CardTitle} from "../ui/Card";
import {Dialog, DialogContent} from "../ui/Dialog";
import {classNames} from "../utils/classes";
import ChartErrorState from "./ChartErrorState";
import ChartLoader from "./ChartLoader";
import DropdownChartOptions from "./DropdownChartOptions";
import PrintSelectedFilters from "./PrintSelectedFilters";

const METRIC_NAME = 'dpe-growth';

export default function DpeGrowth({title}) {
  const {filterState, printingMarketReport} = useContext(FilterContext);
  const [chartType, setChartType] = useState('bar');
  const [transformErrorMessage, setTransformErrorMessage] = useState();
  const [config, setConfig] = useState({});
  const [data, setTransformedData] = useState([]);
  const [printContainerRef, printChart] = usePrint();
  const {metric} = useParams();
  const [, navigate] = useLocation();

  const [rawData, getData, loading, apiErrorMessage] = useDpeGrowth();

  const errorMessage = useMemo(() => apiErrorMessage || transformErrorMessage, [apiErrorMessage || transformErrorMessage]);

  useEffect(() => {
    if (!rawData) return;

    const data = rawData.data;
    setTransformErrorMessage(undefined);

    const transformed = data.reduce((acc, line) => {
      const row = {lob: line.description};

      line.data.forEach((lineData) => {
        row[lineData.dpegrowth_year] = Number.parseInt(lineData.total_direct_premiums_earned);
      });

      acc.push(row);

      return acc;
    }, [])
    .map((line) => {
      filterState.years?.forEach((year) => {
        line[year] ||= 0;
      });

      return line;
    });

    const years = filterState.years;
    const config = years?.reduce((acc, year, idx) => {
      return {
        ...acc,
        [year]: {
          label: year,
          color: selectColorOnGradient(idx / years.length),
        }
      }
    }, {});

    setConfig(config);
    setTransformedData(transformed);
  }, [rawData]);

  useEffect(() => {
    getData();
  }, [filterState]);

  const columns = useReducedColumns('years', 'lob', (value) => millionsAndBillionsFormatter(value, 4));

  const table = useReactTable({
    ...getDefaultReactTableConfig(),
    columns,
    data,
    manualFiltering: true,
  });

  function renderChart() {
    return (
      <div className={loading ? 'opacity-50' : ''}>
        {
          errorMessage ? (
            <>
              <ChartLoader show={loading} />
              <ChartErrorState message={errorMessage} reload={getData} />
            </>
          ) : (
            <>
              <ChartLoader show={loading} />
              {data?.length && chartType === 'bar' ? <ChartBar loading={loading} config={config} className='max-h-[350px] w-full' xAxisDataKey='lob' title={title} data={data} /> : null}
              {data?.length && chartType === 'area' ? <ChartArea loading={loading} config={config} className='max-h-[350px] w-full' xAxisDataKey='lob' title='DPE Growth' data={data} /> : null}
            </>
          )
        }
      </div>
    );
  }

  function doDownload() {
    downloadCsv(METRIC_NAME, data);
  }

  return (
    <Card ref={printContainerRef} className={classNames('bg-gray-700', "print:bg-white print:shadow-none print:text-black print:mx-auto print:rounded-none print:border-none flex flex-col p-0 h-full")}>
      <PrintSelectedFilters show={!printingMarketReport} title={`${title} report`} />
      <CardHeader className='p-3'>
        <CardTitle className='flex print:justify-center justify-between items-center w-full text-md'>
          <div>{title}</div>
          <div className='flex items-center space-x-4 print:hidden'>
            <MagnifyingGlassPlusIcon className='h-5' onClick={() => navigate(`/data/metric/${METRIC_NAME}`)} />
            <Dialog open={metric === METRIC_NAME}>
              <DialogContent disableClose className='w-full h-full flex flex-col justify-start max-w-full !rounded-none border-none'>
                <div className='flex flex-col justify-between overflow-y-auto overflow-x-hidden w-full md:mx-auto relative'>
                  <div className='flex items-center justify-between sticky z-50 top-0 bg-gray-800 w-full py-4 px-4'>
                    <div className='flex items-center justify-start space-x-5'>
                      <Link to='/data'>
                        <ArrowLeftIcon className='h-5'/>
                      </Link>
                      <strong>{title}</strong>
                    </div>
                    <Button onClick={doDownload} variant='link' className='flex items-center space-x-2 p-0' size='sm'>
                      <ArrowDownTrayIcon className='h-5'/>
                    </Button>
                  </div>
                  <div className='space-y-14 p-5 lg:w-1/2 w-full mx-auto'>
                    {renderChart()}
                    {loading ? null : <DataTable className='h-auto' table={table} />}
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            <DropdownChartOptions reload={getData} onPrint={printChart} onDownload={doDownload} metricName={METRIC_NAME} onChangeChartStyle={setChartType} chartTypes={['bar', 'area']} />
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className={classNames(loading ? 'opacity-50' : '', 'p-2 relative h-full transition-opacity print:mx-auto')}>
        {renderChart()}
      </CardContent>
    </Card>
  );
}
