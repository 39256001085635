import NewPhoneNumber from "./NewPhoneNumber";
import PhoneVerification from "./PhoneVerification";
import ProfileConfirmation from "./ProfileConfirmation";
import ProfileMatcher from "./ProfileMatcher";

const USER_SETUP_STEPS = [
  {
    id: 'is_this_you',
    component: ProfileMatcher,
    canGoBack: (_user, matchingProfile) => {
      return !!matchingProfile;
    },
    isComplete: (user, matchingProfile) => {
      return !!user.user_profile_id || !!user.setup_has_declined_found_user || !matchingProfile;
    },
    canSkip: (user) => true,
  },
  {
    id: 'confirm_profile_data',
    component: ProfileConfirmation,
    canGoBack: () => false,
    canSkip: (user) => true,
    isComplete: (user) => {
      return !!user.setup_has_provided_profile_info;
    },
  },
  {
    id: 'new_phone',
    component: NewPhoneNumber,
    canGoBack: () => true,
    isComplete: (user) => {
      return !!user.phone;
    },
    canSkip: (user) => {
      return !!user.phone;
    }
  },
  {
    id: 'phone_verification',
    component: PhoneVerification,
    canGoBack: () => true,
    isComplete: (user) => {
      return !!user.phone_verified;
    },
    canSkip: (user) => false,
  },
];

export default USER_SETUP_STEPS;
