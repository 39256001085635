import {useReactTable} from "@tanstack/react-table";
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Chart} from "react-chartjs-2";
import {Link, useLocation, useParams} from "wouter";
import FilterContext from "../Contexts/FilterContext";
import {getDefaultReactTableConfig} from "../Data";
import useDataApi from "../hooks/useFetchDataApi";
import usePrint from "../hooks/usePrint";
import {Button} from "../ui/Button";
import DataTable from "../ui/DataTable";
import {downloadCsv, millionsAndBillionsFormatter, rightAlignTableCell} from "../utils";
import {selectColorOnGradient} from "../utils/colors";
import {
  ArrowDownTrayIcon, ArrowLeftIcon,
  MagnifyingGlassPlusIcon,
} from "@heroicons/react/24/outline";
import {Card, CardContent, CardHeader, CardTitle} from "../ui/Card";
import {Dialog, DialogContent} from "../ui/Dialog";
import {classNames} from "../utils/classes";
import ChartErrorState from "./ChartErrorState";
import ChartLoader from "./ChartLoader";
import DropdownChartOptions from "./DropdownChartOptions";
import PrintSelectedFilters from "./PrintSelectedFilters";
import * as ChartGeo from 'chartjs-chart-geo';
import { Chart as ChartJS, registerables } from 'chart.js';

ChartJS.register(...registerables, ChartGeo.ChoroplethController, ChartGeo.ProjectionScale, ChartGeo.ColorScale, ChartGeo.GeoFeature);

const METRIC_NAME = 'us-map';

const STABBR_TO_STATE_NAME = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "DC": "District of Columbia",
  "FL": "Florida",
  "GA": "Georgia",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PA": "Pennsylvania",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming",
  "AS": "American Samoa",
  "GU": "Guam",
  "MP": "Northern Mariana Islands",
  "PR": "Puerto Rico",
  "VI": "US Virgin Islands",
  "UM": "US Minor Outlying Islands"
}
const STATE_NAME_TO_ABBR = Object.entries(STABBR_TO_STATE_NAME).reduce((acc, [abbr, name]) => {
  return {
    ...acc,
    [name]: abbr,
  }
}, STABBR_TO_STATE_NAME);

const CustomTooltip = ({ header: {title, color}, dataPoints = [], style, classes }) => {
  return (
    <div className={classNames(classes.join(' '), 'bg-gray-800 space-y-2 font-normal rounded max-w-2xl text-sm border p-2 transition-all ease-in-out transform-gpu z-50 last:border-t')} style={style}>
      <div className='flex items-center space-x-2'>
        <div className='h-3 w-3 rounded-sm' style={{backgroundColor: color}}></div>
        <div className='font-semibold'>{title}</div>
      </div>
      {
        dataPoints.map(({label, value}, index) => (
          <div className='flex w-56 justify-between items-center space-x-4' key={`${label}_${index}`}>
            <div className='max-w-1/2 truncate font-semibold'>{label}</div>
            <div>{value}</div>
          </div>
        ))
      }
    </div>
  );
};

function ChoroplethMap({data: mapData}) {
  const chartRef = useRef(null);
  const [tooltipState, setTooltipState] = useState({
    show: false,
    dataPoints: [],
    style: {},
    classes: []
  });

  const handleTooltip = useCallback((context) => {
    const {tooltip} = context;
    const {raw: {raw, feature}, element: {options: {backgroundColor}}} = tooltip.dataPoints[0];

    if (!raw || !feature) return;

    const dataPoints = raw.map((d) => ({
      label: d.description,
      value: millionsAndBillionsFormatter(d.total_direct_premiums_earned)
    }));

    dataPoints.push({
      label: 'Total',
      value: millionsAndBillionsFormatter(raw.reduce((acc, d) => acc + parseFloat(d.total_direct_premiums_earned), 0), 2),
    });

    const newTooltipState = {
      style: {},
      classes: [],
      header: {
        title: feature.properties.name,
        color: backgroundColor,
      },
      dataPoints,
    };

    if (tooltip.opacity === 0) {
      newTooltipState.classes.push('opacity-none');
      setTooltipState(newTooltipState);
      return;
    }

    if (tooltip.yAlign) {
      newTooltipState.classes.push(tooltip.yAlign);
    } else {
      newTooltipState.classes.push('no-transform');
    }

    newTooltipState.style.opacity = 1;
    newTooltipState.style.position = 'absolute';
    newTooltipState.style.left = tooltip.caretX + 20 + 'px';
    newTooltipState.style.top = tooltip.caretY + 20 + 'px';
    newTooltipState.style.padding = tooltip.padding + 'px ' + tooltip.padding + 'px';
    newTooltipState.style.pointerEvents = 'none';

    newTooltipState.show = true;

    setTooltipState(newTooltipState);
  }, []);

  const options = {
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        external: handleTooltip,
      }
    },
    scales: {
      xy: {
        projection: 'albersUsa',
        grid: {
          display: false,
        },
        display: false
      },
      color: {
        axis: 'x',
        display: false,
        interpolate: (v) => {
          return selectColorOnGradient(v);
        }
      }
    },
    geo: {
      colorScale: {
        display: false
      }
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  if (!mapData) return <div>Loading map data...</div>;

  return (
    <>
      <Chart
        ref={chartRef}
        type="choropleth"
        data={mapData}
        options={options}
        plugins={[ChartGeo]}
      />
      {tooltipState.show ? <CustomTooltip {...tooltipState} /> : null}
    </>
  );
}

export default function UsMap({title}) {
  const {filterState, printingMarketReport} = useContext(FilterContext);
  const [chartType, setChartType] = useState('bar');
  const [transformErrorMessage, setTransformErrorMessage] = useState();
  const [mapData, setMapData] = useState();
  const [mapTableData, setMapTableData] = useState();
  const [printContainerRef, printChart] = usePrint();
  const {metric} = useParams();
  const [, navigate] = useLocation();

  const [fetchDataApi, loading, apiErrorMessage] = useDataApi();

  const getData = useCallback(async () => {
    return fetchDataApi('/api/data/charts/dpe-map', 'POST')
    .then(({data}) => {
      setTransformErrorMessage(undefined);

      async function fetchGeoData() {
        const response = await fetch('https://unpkg.com/us-atlas@3.0.1/states-10m.json');
        const us = await response.json();
        const states = ChartGeo.topojson.feature(us, us.objects.states).features;

        const newData = data.reduce((acc, d) => {
          return {...acc, [STABBR_TO_STATE_NAME[d.dpegrowth_stabbr]]: d.data}
        }, {});

        const mapData = states.filter((d) => {
          return !!newData[d.properties.name];
        }).map((d, i) => {
          const total = newData[d.properties.name].reduce((acc, d) => acc + parseFloat(d.total_direct_premiums_earned), 0);

          return {
            feature: d,
            value: total,
            raw: newData[d.properties.name],
          }
        });

        setMapData({
          labels: states.map(d => d.properties.name),
          datasets: [{
            label: 'States',
            data: mapData,
          }]
        });

        const tableMapData = mapData
        .filter((d) => !!STATE_NAME_TO_ABBR[d.feature.properties.name])
        .map((d) => {
          const lobs = d.raw.reduce((acc, {description: lob, total_direct_premiums_earned: value}) => {
            return {
              ...acc,
              [lob]: millionsAndBillionsFormatter(value, 2),
            }
          }, {})

          return {
            ...lobs,
            state: d.feature.properties.name,
          }
        })

        setMapTableData(tableMapData.sort((a, b) => a.state > b.state ? 1 : -1));
      }

      fetchGeoData();
    })
  }, [filterState]);

  const errorMessage = useMemo(() => apiErrorMessage || transformErrorMessage, [apiErrorMessage || transformErrorMessage]);

  useEffect(() => {
    getData();
  }, [filterState]);

  // const columns = useReducedColumns('years', 'lob', (value) => millionsAndBillionsFormatter(value, 4));
  const columns = [
    {
      accessorKey: 'state',
      header: 'State',
    },
  ]

  filterState.lob.forEach((lob) => {
    columns.push({
      accessorKey: lob,
      header: rightAlignTableCell(lob),
      cell: ({getValue}) => rightAlignTableCell(getValue()),
    })
  });

  const table = useReactTable({
    ...getDefaultReactTableConfig(),
    columns,
    data: mapTableData || [],
  });

  function renderChart() {
    return (
      <div className={classNames(loading ? 'opacity-50' : '', 'h-full w-full max-h-[300px] sm:max-h-[400px]')}>
        {
          errorMessage ? (
            <>
              <ChartLoader show={loading} />
              <ChartErrorState message={errorMessage} reload={getData} />
            </>
          ) : (
            <>
              <ChartLoader show={loading} />
              {!!mapData ? <ChoroplethMap data={mapData} /> : null}
            </>
          )
        }
      </div>
    );
  }

  function doDownload() {
    downloadCsv(METRIC_NAME, mapTableData);
  }

  return (
    <Card ref={printContainerRef} className={classNames('bg-gray-700', "print:bg-white print:shadow-none print:text-black print:mx-auto print:rounded-none print:border-none flex flex-col p-0 h-full")}>
      <PrintSelectedFilters show={!printingMarketReport} title={`${title} report`} />
      <CardHeader className='p-3'>
        <CardTitle className='flex print:justify-center justify-between items-center w-full text-md'>
          <div>{title}</div>
          <div className='flex items-center space-x-4 print:hidden'>
            <MagnifyingGlassPlusIcon className='h-5' onClick={() => navigate(`/data/metric/${METRIC_NAME}`)} />
            <Dialog open={metric === METRIC_NAME}>
              <DialogContent disableClose className='w-full h-full flex flex-col justify-start max-w-full !rounded-none border-none'>
                <div className='flex flex-col justify-between overflow-y-auto overflow-x-hidden h-full w-full md:mx-auto relative'>
                  <div className='flex items-center justify-between sticky z-50 top-0 bg-gray-800 w-full py-4 px-4'>
                    <div className='flex items-center justify-start space-x-5'>
                      <Link to='/data'>
                        <ArrowLeftIcon className='h-5'/>
                      </Link>
                      <strong>{title}</strong>
                    </div>
                    <Button onClick={doDownload} variant='link' className='flex items-center space-x-2 p-0' size='sm'>
                      <ArrowDownTrayIcon className='h-5'/>
                    </Button>
                  </div>
                  <div className='space-y-14 p-5 lg:w-3/4 w-4/5 mx-auto relative overflow-visible flex-col justify-between'>
                    {renderChart()}
                    {loading ? null : (
                      <DataTable className='h-[500px]' table={table} />
                    )}
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            <DropdownChartOptions reload={getData} onPrint={printChart} onDownload={doDownload} metricName={METRIC_NAME} />
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className={classNames(loading ? 'opacity-50' : '', 'p-2 relative h-full transition-opacity print:mx-auto')}>
        {renderChart()}
      </CardContent>
    </Card>
  );
}
