import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useSessionStorage} from "react-use";
import {useLocation} from "wouter";
import Spinner from "./Spinner";
import useUserProfileCompletionCalculation from "./UserSetup/useUserProfileCompletionCalculation";

export default function AppLoadNavigationManager() {
  const me = useSelector((state) => state.user);
  const [initNavigationManaged, setInitNavigationManaged] = useState(false);
  const [, navigate] = useLocation();
  const [hasShownUserSetup, setHasShownUserSetup] = useSessionStorage('user-setup', false);

  const [profileCompletionLoaded, {percentage: profileCompletionPercentage}] = useUserProfileCompletionCalculation();

  useEffect(() => {
    if (!me.user.hasLoaded || !profileCompletionLoaded) return;

    setInitNavigationManaged(true);

    if (profileCompletionPercentage < 100 && !hasShownUserSetup) {
      setHasShownUserSetup(true);
      return navigate('/user/setup');
    }

    if (!me.user.channels.length) return navigate('/switchboard/people');
  }, [me.user.hasLoaded, profileCompletionLoaded, profileCompletionPercentage]);

  if (initNavigationManaged) return null;

  return (<AppLoader />);
}

export function AppLoader() {
  return (
    <div className='h-full w-full'>
      <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
        <Spinner className='h-10' />
      </div>
    </div>
  );
}
